import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const Installation = (props) => {

    return (
        <>
            <header id="header">
                <div className="intro2" >
                    <div className="overlay2">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="left-install-wrapper">
                                    {/* Left Column for Image */}
                                    <div className="col-md-6 col-xs-12 left-install">
                                        <img
                                            src={props?.data ? props.data.Phone : ''}
                                            alt="error"
                                        />
                                    </div>

                                    {/* Right Column for Text */}
                                    <div className="col-md-6 col-xs-12 top-install">
                                        <h2>India's #1 Printing App</h2>
                                        <p>"No More WhatsApp Direct, Instant, and Secure Printing."</p>
                                        <a href="https://play.google.com/store/apps/details?id=com.smit.smitprint">
                                            <img
                                                src={props?.data ? props.data.playstore : ''}
                                                alt="error"
                                            />
                                        </a>
                                        <Link to='/privacy-policy'> <p style={{ marginLeft: '5%', textDecoration: 'underline', fontSize: '1rem' }}>Privacy & Policy</p></Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* <div id="footer">
                <div className="container d-flex justify-content-center align-items-center text-center">
                    <p>
                        &copy; 2024 <span style={{ color: "#f25c05", fontFamily: "CarotSans-Bold" }}>Print</span>Anywhere | Design by{" "}
                        <a href="https://www.smitsolutions.co.in/" rel="nofollow">
                            <img src={props.data ? props.data.logoImg : ''} alt="TemplateWire Icon" />
                        </a>
                    </p>
                </div>
            </div> */}

        </>

    );
};
