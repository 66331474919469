import React from 'react';
import '../Font/pages.css';

const Page = ({ img, title, subMain, subText, listTitle, list, titleList, printLogo, FirstPage, waterMark, LastPage, mainList }) => {

    return (
        <div id="page">

            {/* <h2>Step 1: Upload Your File</h2>
            <div className='page-img'>
                <img src="/img/step4.png" alt="SMIT Logo" />
            </div> */}

            {/* {waterMark !== "" ?
                <div className='.print-water-mark'>
                    <img src={waterMark} alt="SMIT Logo" style={{width:'100px'}}/>
                    <p><span style={{ color: "#f25c05" }}>Print</span>Anywhere</p>
                </div>
                : null
            } */}

            {/* page - 1 */}
            {printLogo !== "" ?
                <div className='print-logo'>
                    <img src={printLogo} alt="SMIT Logo" />
                    <h3><span style={{ color: "#f25c05" }}>Print</span>Anywhere</h3>
                </div>
                : null
            }

            {FirstPage !== '' ?
                <div className='print-user'>
                    <h1><span style={{ color: "#f25c05" }}>Print</span>Anywhere</h1>
                    <h1 id='print-id'>User Guide</h1>
                    <p>Welcome to <span style={{ color: "#f25c05", fontFamily: "CarotSans-Bold" }}>Print</span>Anywhere —your one-stop solution for hassle-free and efficient printing! Follow this guide to understand how to use our app for seamless printing anytime, anywhere.</p>
                </div>
                : null
            }

            <h2>{title !== "" ? title : null}</h2>
            {img !== "" ?
                <div className='page-img'>
                    <img src={img} alt="SMIT Logo" />
                </div>
                : null
            }

            {/* Referencing the image from the public folder */}
            {/* <h4><span>Supported Formats</span>: You can upload PDFs, JPGs, and PNGs. </h4>
            <p><span>How to Upload</span>: </p> */}

            {subMain !== "" || subText !== "" || listTitle !== "" ?
                <>
                    <h4><span>{subMain !== "" ? subMain : null}</span></h4>
                    <p>{subText !== "" ? subText : null}</p>
                    <p><span>{listTitle}</span></p>
                </>
                : null
            }


            {/* <ul className="upload-steps">
                <li>Tap the <strong>Upload</strong> button on the home screen.</li>
                <li>Select your file from your device or cloud storage.</li>
                <li>Preview your file once uploaded to ensure everything looks perfect.</li>
            </ul> */}

            {list.length > 0 ?
                <ul className="upload-steps">
                    {list.map((item, index) => (
                        <li key={index}>
                            {item.split('**').map((part, partIndex) => (
                                <span key={partIndex}>
                                    {partIndex % 2 === 1 ? <strong>{part}</strong> : part}
                                </span>
                            ))}
                        </li>
                    ))}
                </ul> : null
            }

            {mainList.length > 0 ?
                <ul className="upload-mainlist">
                    {mainList.map((item, index) => (
                        <li key={index}>
                            {item.split('**').map((part, partIndex) => (
                                <span key={partIndex}>
                                    {partIndex % 2 === 1 ? <strong>{part}</strong> : part}
                                </span>
                            ))}
                        </li>
                    ))}
                </ul> : null
            }

            {titleList.length > 0 ? titleList.map((val, index) => (
                <div className='titleList' key={index}>
                    <p><span>{val.title}</span></p>
                    <ul className="upload-steps">
                        {val.list.map((item, index) => (
                            <li key={index}>
                                {item.split('**').map((part, partIndex) => (
                                    <span key={partIndex}>
                                        {partIndex % 2 === 1 ? <strong>{part}</strong> : part}
                                    </span>
                                ))}
                            </li>
                        ))}
                    </ul>
                </div>
            )) : null
            }

            {LastPage !== '' ?
                <div className='print-user'>
                    <h1>Contact Support</h1>
                    <p>Need help? Reach out to us anytime through the <strong>Help & Support</strong> section in the app or email us at <strong>support@printanywhere.in</strong>.</p>
                    <p>Start your effortless printing journey with <span style={{ color: "#f25c05", fontFamily: "CarotSans-Bold" }}>Print</span>Anywhere today! 🎉</p>
                </div>
                : null
            }


        </div>
    );
};

export default Page;
