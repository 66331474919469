import React, { useState, useEffect } from 'react';
import HTMLFlipBook from 'react-pageflip';
import Page from './page';

const Guides = (props) => {
    const [bookDimensions, setBookDimensions] = useState({ width: 550, height: 645 });

    // Use a ref to get the container's width
    const bookRef = React.useRef(null);

    useEffect(() => {
        const updateDimensions = () => {
            if (bookRef.current) {
                const containerWidth = bookRef.current.offsetWidth;

                // For mobile devices (max width 480px)
                if (containerWidth <= 375) {
                    setBookDimensions({ width: containerWidth - 10, height: containerWidth * 1.5 }); // 550:645 ratio
                }
                else if (containerWidth <= 480) {
                    setBookDimensions({ width: containerWidth - 10, height: containerWidth * 1.5 }); // 550:645 ratio
                }
                // For tablets and small screens (max width 768px)
                else if (containerWidth <= 768) {
                    setBookDimensions({ width: containerWidth * 0.9, height: (containerWidth * 0.9) * 1.5 });
                }
                // For larger screens, use a fixed width
                else {
                    setBookDimensions({ width: 550, height: 645 });
                }
            }
        };

        // Add event listener to handle window resizing
        window.addEventListener('resize', updateDimensions);
        updateDimensions(); // Set initial dimensions

        // Clean up event listener
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <div id='user' ref={bookRef}>
            <HTMLFlipBook
                width={bookDimensions.width}
                height={bookDimensions.height}
                className='htmlflip'
            >
                {props?.data?.length > 0 ?
                    props.data.map((item, index) => (
                        <div className="demoPage" key={index}>
                            <Page
                                img={item.img}
                                list={item.list}
                                listTitle={item.listTitle}
                                title={item.title}
                                subMain={item.subMain}
                                subText={item.subText}
                                titleList={item.titleList}
                                printLogo={item.printLogo}
                                FirstPage={item.FirstPage}
                                waterMark={item.waterMark}
                                LastPage={item.LastPage}
                                mainList={item.mainList}
                            />
                        </div>
                    ))
                    : null
                }
            </HTMLFlipBook>
            <div className="swipe-text">
                Swipe
            </div>
        </div>
    );
}

export default Guides;
