import React from 'react';

const Footer = (props) => {
    return (
        <>
            <div id="footer">
                <div className="container d-flex justify-content-center align-items-center text-center">
                    <p>
                        &copy; 2024 <span style={{ color: "#f25c05", fontFamily: "CarotSans-Bold" }}>Print</span><span style={{color:'#000'}}>Anywhere</span> | Design by{" "}
                        <a href="https://www.smitsolutions.co.in/" rel="nofollow">
                            <img src={props.data ? props.data.logoImg : ''} alt="TemplateWire Icon" />
                        </a>
                    </p>
                </div>
            </div>

        </>
    );
}

export default Footer;
