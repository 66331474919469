import axios from "axios";
import React, { useEffect, useState } from 'react'
import JsonData from "../data/data.json";
import Footer from "./footer";


const initialState = {
    message: "",
    mobileNumber: "",
};

const DeleteAcount = () => {
    const [{ mobileNumber, message }, setState] = useState(initialState);
    const [status, setStatus] = useState('');

    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        // If the field is mobileNumber, enforce numeric input and a maximum of 10 digits
        if (name === "mobileNumber") {
            // Allow only digits and limit to 10 characters
            const formattedValue = value.replace(/[^0-9]/g, "").slice(0, 10); // Remove non-numeric characters and slice to 10 digits
            setState((prevState) => ({ ...prevState, [name]: formattedValue }));
        } else {
            // For other fields, just update the state as normal
            setState((prevState) => ({ ...prevState, [name]: value }));
        }
    };
    const clearState = () => setState({ ...initialState });

    function obj(mobileNumber, message) {
        const data = {
            mobileNumber: mobileNumber.trim(),
            reason: message.trim()
        };
        return data;
    };

    async function sha256(inputString) {
        const encoder = new TextEncoder();
        const data = encoder.encode(inputString);

        // Compute the SHA-256 hash
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);

        // Convert the hash buffer to a hexadecimal string
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

        return hashHex;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(name, email, message);


        // Mobile number validation regex
        const mobileNumberRegex = /^[6-9]\d{9}$/;


        // Check if mobile number is valid
        if (!mobileNumberRegex.test(mobileNumber.trim())) {
            alert("Invalid mobile number format");
            return;  // Stop submission if mobile number is invalid
        }

        const postData = obj(mobileNumber, message);

        let Key = 'deleteaccountkakartaytumhimahitinahipnaccountdeletenavvaddivasatpurnhoil';
        let key_mob = Key.concat(mobileNumber);

        const hash = await sha256(key_mob);

        try {
            const response = await axios.post(`https://smitprint.printanywhere.in/web/account/delete`, postData, {
                headers: {
                    'Content-Type': 'application/json',
                    'WebTicket': hash
                },
            });
            // console.log(response);
            let status = response.status;
            setStatus(status);
        }
        catch (error) {
            // Handle error if the request fails
            // console.error('Error during POST request:', error);
            let status = error.status;
            setStatus(status);
        }
    };
    return (
        <>
            <div id="contact">
                <div className="container">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="section-title">
                                <h2>Delete Your Account</h2>
                                <p>
                                    To proceed with the deletion of your account, please fill out the form below:
                                </p>
                            </div>
                            {status == '422' ? <h6 style={{ color: 'red', fontSize: '1.2rem' }}>* A request for this mobile number is already in process</h6> : null}
                            {status == '200' ? (
                                <h3>
                                    Thank you for completing the form. We have received your request, and your account is scheduled for deletion within the next 90 days.
                                    If you have any questions or concerns, feel free to reach out to us at support@printanywhere.in.
                                </h3>
                            ) :
                                <form form name="sentMessage" validate onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    id="mobileNumber"
                                                    name="mobileNumber"
                                                    className="form-control"
                                                    placeholder="Mobile"
                                                    required
                                                    maxLength="10" // Limit input to 10 digits in the HTML
                                                    value={mobileNumber} // Make sure this is tied to state
                                                    onChange={handleChange}
                                                />
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea
                                                    name="message"
                                                    id="message"
                                                    className="form-control"
                                                    rows="4"
                                                    placeholder="Reason"
                                                    required
                                                    maxLength="500"
                                                    onChange={handleChange}
                                                    style={{ resize: 'none' }}
                                                ></textarea>
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="success"></div>
                                    <button type="submit" className="btn btn-custom btn-lg">
                                        Send OTP
                                    </button>
                                </form>
                            }
                        </div>
                    </div>
                </div>

            </div >
            <Footer data={landingPageData.Install} />
        </>
    );
}

export default DeleteAcount;
