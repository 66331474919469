import React, { useEffect, useState } from "react";
import "../Font/font.css";
import { Link as ScrollLink, scroller } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";

export const Navigation = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(""); // Track active section
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // Effect to track the route changes (for route-based navigation)
  useEffect(() => {
    const path = location.pathname;

    if (path === "/user-guide") {
      setActiveSection("user-guide");  // Set active to guides when on '/guides'
    } else if (path === "/") {
      setActiveSection(activeSection);  // Reset active section when on home page
    } else {
      setActiveSection("");  // Reset active section for other routes
    }
  }, [location.pathname]);

  const handleNavigation = (sectionId) => {
    setActiveSection(sectionId); // Update active section

    if (location.pathname !== "/") {
      navigate("/");
    }

    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        smooth: true,
        duration: 500,
        offset: -70,
      });
    }, 100);
    setIsMenuOpen(prevState => !prevState);

  };
  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const handleNavigateClick = (path) => {
    navigate(`/${path}`);
    setIsMenuOpen(prevState => !prevState);
  }
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-target="#bs-example-navbar-collapse-1"
            onClick={toggleMenu}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            className="navbar-brand page-scroll"
            href="/"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={props.data ? props.data.logo : ""}
              alt="Logo"
            />
            <span style={{ color: "#f25c05", fontFamily: "CarotSans-Bold" }}>Print</span>Anywhere
          </a>{" "}
        </div>

        <div className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <button
                className={`page-scroll ${activeSection === "header" ? "active" : ""}`}
                onClick={() => handleNavigation("header")}
              >
                Home
              </button>
            </li>
            <li>
              <button
                className={`page-scroll ${activeSection === "features" ? "active" : ""}`}
                onClick={() => handleNavigation("features")}
              >
                Features
              </button>
            </li>
            <li>
              <button
                className={`page-scroll ${activeSection === "about" ? "active" : ""}`}
                onClick={() => handleNavigation("about")}
              >
                About
              </button>
            </li>
            <li>
              <button
                className={`page-scroll ${activeSection === "contact" ? "active" : ""}`}
                onClick={() => handleNavigation("contact")}
              >
                Become Partner
              </button>
            </li>
            <li>
              <button
                className={`${activeSection === "user-guide" || location.pathname === "/user-guide" ? "active" : ""}`}
                onClick={() => handleNavigateClick('user-guide')}
              >
                User Guide
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
